@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap");

/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: #eeeef2;
  color: #444;
  display: flex;
  justify-content: space-between;
  border: none;
  outline: none;
  flex-direction: row;
  padding: 16px;
  height: 56px;
  background: #eeeef2;
  border-radius: 20px;
  margin-bottom: 8px;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion.active {
  background-color: #FFF;
  border-radius: 20px 20px 0px 0px;
  margin-bottom: 0px;
}

/* Style the accordion content title */
.accordion__title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #5C7190;
}


/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(90deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  background: #FFF;
  border-radius: 0px 0px 20px 20px;
  overflow: hidden;
  transition: max-height 0.6s ease;
  margin-bottom: 10px;
}

/* Style the accordion content text */
.accordion__text {
  padding: 18px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #4A4A4A;
}